import * as React from "react";
import { ApolloClient } from "apollo-boost";
import { useQueryCovidIntentByName } from "../../../hooks/live-queries/use-query-intent-by-name/use-covid-intent-by-name";
import LoadingState from "../../ui-library/loading-state/loading-state";
import {
  FBIntentInput,
  AskAboutTopics,
} from "../../../apollo-types/globalTypes";
import { useQueryCovidLiveIntents } from "../../../hooks/live-queries/use-query-live-intents/use-query-live-intents";
import { IntentDetailFrament_category } from "../../../apollo-types/IntentDetailFrament";
import { useCovidIntentCategoriesQuery } from "../../../hooks/static-queries/use-covid-intent-categories-query";
import { Topic } from "../../../types/topics";
import IntentForm from "./intent-form";
import { updateIntentByTopic } from "./intent-admin-services";

interface EditIntentCovidProps {
  alias: string;
  intentName: string;
  displayName: string;
  client: ApolloClient<unknown>;
}

const EditIntentCovid: React.FC<EditIntentCovidProps> = ({
  intentName,
  client,
}) => {
  const { intent, loading } = useQueryCovidIntentByName(intentName);
  const { liveIntents } = useQueryCovidLiveIntents();
  const categories: IntentDetailFrament_category[] =
    useCovidIntentCategoriesQuery() || [];

  if (loading) {
    return <LoadingState />;
  }

  const handleSubmit = (input: FBIntentInput) =>
    updateIntentByTopic({ client, topic: AskAboutTopics.COVID19, input });

  return (
    <div>
      <IntentForm
        intent={intent}
        liveIntents={liveIntents || []}
        categories={categories}
        isUpdateIntent={true}
        onSubmit={handleSubmit}
        topic={Topic.COVID19}
      />
    </div>
  );
};

export default EditIntentCovid;
