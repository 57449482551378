import * as React from "react";
import { Link, PageProps } from "gatsby";
import { useGetAuthToken } from "../../../hooks/use-get-auth-token";
import LoadingState from "../../../components/ui-library/loading-state/loading-state";
import { BasicLayout } from "../../../components/layout";
import EditIntentCovid from "../../../components/internal/intent-editor/edit-intent-covid";
import { createApolloClient } from "../../../core/create-apollo-network-layer";
import { API_URL } from "../../../constants/urls";
import SignInNudge from "../../../components/internal/sign-in-nudge/sign-in-nudge";

type EditIntentPageProps = PageProps<null, null>;

interface ActiveIntent {
  alias: string;
  intentName: string;
  displayName: string;
}

const EditIntentPage: React.FC<EditIntentPageProps> = ({ location }) => {
  const { loading, userToken } = useGetAuthToken();

  // @ts-ignore no activeIntent
  const activeIntent: ActiveIntent | undefined = location?.state?.activeIntent;

  if (loading) {
    return (
      <BasicLayout>
        <LoadingState />
      </BasicLayout>
    );
  }

  if (!userToken) {
    return (
      <BasicLayout>
        <SignInNudge />
      </BasicLayout>
    );
  }

  if (!activeIntent) {
    return (
      <BasicLayout>
        <div>No intent selected to edit</div>
        <div>
          Go back to{" "}
          <Link to="/internal/covid-19/all-intents/">All intents</Link>
        </div>
      </BasicLayout>
    );
  }

  const askAboutApolloClient = createApolloClient({
    uri: API_URL,
    token: userToken,
  });

  return (
    <BasicLayout>
      <EditIntentCovid {...activeIntent} client={askAboutApolloClient} />
    </BasicLayout>
  );
};

export default EditIntentPage;
